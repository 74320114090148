import * as React from "react";
import Container from "@mui/material/Container";
import { Intro, SafeArea, ModelLoader, Footer, Header } from "./components";
import { Grid, styled } from "@mui/material";

const StyledContainer = styled(Container)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export default function App() {
  return (
    <SafeArea>
      <StyledContainer>
        <Grid container spacing={1}>
          <Grid item xs={12} md={10} />
          <Grid item xs={12} md={2}>
            <Header />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={4} alignSelf="flex-end">
            <Intro />
          </Grid>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={5} alignSelf="center">
            <ModelLoader />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={4}>
            <Footer />
          </Grid>
        </Grid>
      </StyledContainer>
    </SafeArea>
  );
}
