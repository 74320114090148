import { Box, styled } from "@mui/material";

const SafeArea = styled(Box)(({ theme }) => ({
  //   paddingTop: 50,
  //   paddingBottom: 128,
  width: "100vw",
  minHeight: "100vh",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("md")]: {
    minHeight: "auto",
    height: "100vh",
  },
}));

export default SafeArea;
