import { styled, Typography } from "@mui/material";
import React from "react";

const StyledTypography = styled(Typography)({
  marginTop: 50,
  fontSize: "1.5rem",
});

const Header = () => {
  return <StyledTypography color="secondary">DJW.WORLD</StyledTypography>;
};

export default Header;
