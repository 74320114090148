import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ["Courier New", "monospace"].join(","),
    h1: {
      fontSize: "5rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem",
    },
  },
  palette: {
    primary: {
      main: "#15392F",
    },
    secondary: {
      main: "#C6B79B",
    },
  },
});

export default theme;
