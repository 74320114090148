import { Box, Divider, styled, Typography } from "@mui/material";
import React from "react";

const StyledDivider = styled(Divider)({
  width: "2rem",
  marginInlineStart: "unset",
  borderWidth: "0.125rem",
  borderStyle: "solid",
  backgroundColor: "white",
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
});

const Intro = () => {
  return (
    <Box component="div">
      <Typography variant="h1" color="white">
        Say Hi.
      </Typography>
      <Typography variant="h2" color="white" sx={{ pt: 3 }}>
        to
      </Typography>
      <Typography variant="h2" color="white">
        Davin Jaya Wisastra
      </Typography>
      <StyledDivider />
      <Typography variant="body1" color="white">
        A software developer that believe in the importance of UI/UX and also in
        love with musics! Reach him out!
      </Typography>
      <StyledDivider />
      <Typography variant="body2" color="white">
        v2.2.2
      </Typography>
    </Box>
  );
};

export default Intro;
