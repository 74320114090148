import { Box, Link, styled } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  height: 128,
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  [theme.breakpoints.up("md")]: {
    flexWrap: "nowrap",
  },
}));

const StyledLink = styled(Link)({
  marginRight: "1.5rem",
  fontSize: "1.5rem",
});

const Footer = () => {
  return (
    <StyledBox>
      <StyledLink
        color="secondary"
        href="https://www.instagram.com/davindjw"
        target="_blank"
      >
        Instagram
      </StyledLink>
      <StyledLink
        color="secondary"
        href="https://www.linkedin.com/in/davin-jaya-wisastra-38513811a"
        target="_blank"
      >
        Linkedin
      </StyledLink>
      <StyledLink color="secondary" href="mailto:davinjayawork@gmail.com">
        Mail
      </StyledLink>
      <StyledLink
        color="secondary"
        href="https://github.com/davinjaya98"
        target="_blank"
      >
        Github
      </StyledLink>
    </StyledBox>
  );
};

export default Footer;
