import React, { useRef, useState } from "react";
const Spline = React.lazy(() => import("@splinetool/react-spline"));
import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { Application, SplineEvent } from "@splinetool/runtime";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const ModelLoader = () => {
  const spline = useRef<Application>();
  const [loading, isLoading] = useState<Boolean>(true);

  const loadSpline = (splineApp: Application) => {
    spline.current = splineApp;
    isLoading(false);
  };

  return (
    <StyledBox>
      {loading && <CircularProgress color="secondary" />}
      <Box
        component="div"
        sx={{
          canvas: {
            width: "100%!important",
            height: "auto!important",
          },
        }}
      >
        <Spline
          scene="https://s3.ap-southeast-1.amazonaws.com/djw.world/assets/room_portfolio.spline"
          onLoad={loadSpline}
          style={{
            opacity: loading ? 0 : 1,
          }}
        />
      </Box>
      <Typography color="white" variant="body2" sx={{ mt: 2 }}>
        Try clicking anything! Some objects are clickable ;)
      </Typography>
    </StyledBox>
  );
};

export default ModelLoader;
